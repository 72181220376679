import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import CarouselHome from '../../components/FootId/CarouselHome'
import CarouselHomeLojas from '../../components/FootId/CarouselHome/lojas'
import TiposPisadas from '../../components/FootId/TiposPisadas'
import { ditoTrack } from '../../helpers/dito'

import {
  icoAgenda,
  icoConcluido,
  icoTeste,
  logo,
  lojaGenerica,
  neutra,
  pronada,
  supinada,
  topo1,
  topo2,
  topo3,
  topo4,
} from '../../images/foot-id'

import * as S from './styles'

const FootIdPage = ({ data }) => {
  useEffect(() => {
    ditoTrack('acessou-foot-id')
  }, [])

  const BANNERS = {
    topo1,
    topo2,
    topo3,
    topo4,
  }

  const PISADAS = {
    supinada,
    neutra,
    pronada,
  }

  return (
    <Layout>
      <SEO
        title="FOOT ID"
        description="Deixe seus pés falarem: Sua pisada pode definir tudo sobre você e a sua corrida"
      />

      <CarouselHome banners={BANNERS} logo={logo} />

      <TiposPisadas pisadas={PISADAS} />

      <S.ComoRealizar>
        <h1>
          Como realizar o <strong>FOOT-ID</strong>
        </h1>
        <h2>
          Você pode agendar a sua avaliação em uma loja ASICS de forma gratuita.
        </h2>
        <S.WrapperRealizar>
          <S.CardRealizar>
            <S.IconeRealizar>
              <img src={icoTeste} alt="Teste em 5 minutos" />
            </S.IconeRealizar>
            <S.ContentRealizar>
              <p>
                O teste é rápido <br /> dura no máximo <br /> 5 minutos.
              </p>
            </S.ContentRealizar>
          </S.CardRealizar>
          <S.CardRealizar>
            <S.IconeRealizar>
              <img src={icoAgenda} alt="Reserve uma data" />
            </S.IconeRealizar>
            <S.ContentRealizar>
              <p>
                Reserve a data e a loja que você vai realizar o teste e leve
                tênis e roupas leves. Caso você não tenha um ou esqueça de
                levar, a equipe poderá te fornecer um modelo para a avaliação.
              </p>
            </S.ContentRealizar>
          </S.CardRealizar>
          <S.CardRealizar>
            <S.IconeRealizar>
              <img src={icoConcluido} alt="Resultado do teste" />
            </S.IconeRealizar>
            <S.ContentRealizar>
              <p>
                Após concluir o teste, você receberá o resultado junto com a
                indicação sobre quais os melhores calçados para você
              </p>
            </S.ContentRealizar>
          </S.CardRealizar>
        </S.WrapperRealizar>
      </S.ComoRealizar>

      <S.Lojas>
        <h1>
          Encontre a loja <strong>mais perto de você</strong>
        </h1>

        <S.LojasWrapper>
          <CarouselHomeLojas data={data} lojaGenerica={lojaGenerica} />
        </S.LojasWrapper>
      </S.Lojas>
    </Layout>
  )
}

export default FootIdPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFootIdLoja(filter: { alternative_id: { ne: 0 } }) {
      edges {
        node {
          foto
          nome
          endereco
          numero
          telefone
          url_navegacao
          cidade {
            nome
            uf
          }
        }
      }
    }
  }
`

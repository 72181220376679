import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import NavLink from '../../../components/Buttons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
SwiperCore.use([Navigation, Pagination, Autoplay])

const LojasItemSlide = styled.div`
  align-items: center;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  height: 275px;
  justify-content: center;
  width: 100%;

  h2 {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 19px;
    line-height: 14px;
  }

  p {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 5px;
  }

  a {
    color: var(--primary-color);
    font-size: 17px;
    text-decoration: none;
  }
`
const ThumbLojasSlide = styled.figure`
  border: 3px solid #afe2be;
  border-radius: 50%;
  display: flex;
  height: 164px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 164px;

  img {
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
    width: 100%;
  }
`
const LinkThumbLojaSlide = styled.a`
  display: block;
  height: 100%;
  width: 100%;
`
export default function CarouselHomeLojas({ data, lojaGenerica }) {
  return (
    <>
      <Swiper
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
      >
        {data.allFootIdLoja.edges.map((loja) => (
          <SwiperSlide key={`loja-${loja.node.nome}`}>
            <LojasItemSlide>
              <ThumbLojasSlide>
                <LinkThumbLojaSlide
                  target="_blank"
                  href={loja.node.url_navegacao}
                  rel="noreferrer"
                >
                  <img
                    src={loja.node.foto || lojaGenerica}
                    alt={loja.node.nome}
                  />
                </LinkThumbLojaSlide>
              </ThumbLojasSlide>
              <h2>
                <LinkThumbLojaSlide
                  target="_blank"
                  href={loja.node.url_navegacao}
                  rel="noreferrer"
                >
                  {loja.node.nome}
                </LinkThumbLojaSlide>
              </h2>
              <p>
                {loja.node.endereco} - {loja.node.numero}
              </p>
              {loja.node.telefone !== null && (
                <a href={`tel://${loja.node.telefone}`}>{loja.node.telefone}</a>
              )}
            </LojasItemSlide>
          </SwiperSlide>
        ))}
      </Swiper>
      <NavLink to="/foot-id/agendamento" size="normal">
        agende seu teste grátis
      </NavLink>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

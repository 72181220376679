import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import NavLink from '../../Buttons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
SwiperCore.use([Navigation, Pagination, Autoplay])

const Banner = styled.section`
  background-color: var(--primary-color);
  height: 400px;
  margin-bottom: 30px;
  width: 100%;

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }
`

const BannerItem = styled.div`
  background-position: center top;
  background-size: cover;
  height: 400px;
  width: 100%;

  @media (max-width: 767px) {
    background-image: none !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    background-image: none !important;
  }
`

const BannerText = styled.div`
  align-items: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 50px;
  text-align: ${({ reverse }) => (reverse ? 'right' : 'left')};
  width: 1170px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  h1 {
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 35px;
    }
  }

  p {
    font-size: 19px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    width: 500px;

    @media (max-width: 767px) {
      font-size: 18px;
      width: 100%;
    }
  }
`

export default function CarouselHome({ banners, logo }) {
  const { topo1, topo2, topo3, topo4 } = banners

  return (
    <Banner>
      <Swiper
        slidesPerView={1}
        navigation
        autoplay={{ delay: 4000 }}
        pagination={{ clickable: true }}
        loop={true}
      >
        <SwiperSlide>
          <BannerItem style={{ backgroundImage: `url(${topo1})` }}>
            <BannerText>
              <img src={logo} alt="Foot ID" />
              <h1>
                <strong>Deixe seus pés falarem:</strong>
                <br />
                Sua pisada pode definir tudo
                <br />
                sobre você e a sua corrida
              </h1>
              <NavLink
                color="primary"
                bgcolor="white"
                size="small"
                to="/foot-id/agendamento"
              >
                agende seu teste
              </NavLink>
            </BannerText>
          </BannerItem>
        </SwiperSlide>
        <SwiperSlide>
          <BannerItem style={{ backgroundImage: `url(${topo2})` }}>
            <BannerText reverse>
              <h1>
                O que é o <strong>ASICS FOOT-ID</strong>
              </h1>
              <p>
                Unindo tecnologia e uma equipe especializada, a ASICS
                desenvolveu um programa que identifica o seu tipo de pisada.
                <br />
                <br />
                Ao fazer uma análise biomecânica completa e traçar o seu perfil,
                você será capaz de encontrar o seu tênis ideal e melhorar seu
                desempenho na corrida. Nossas unidades contam com uma equipe de
                especialistas pronta para te orientar e encontrar o ajuste
                perfeito pra você, por meio do ASICS FOOT-ID.
              </p>
              <NavLink
                color="primary"
                bgcolor="white"
                size="small"
                to="/foot-id/agendamento"
              >
                agende seu teste
              </NavLink>
            </BannerText>
          </BannerItem>
        </SwiperSlide>
        <SwiperSlide>
          <BannerItem style={{ backgroundImage: `url(${topo3})` }}>
            <BannerText>
              <p>
                Essa tecnologia foi desenvolvida pelo ASICS Institute of Sport
                Science em Kobe, no Japão. Aqui no Brasil, a ASICS oferece esse
                serviço gratuito em diversas lojas e funciona da seguinte forma:
                você caminha em uma esteira enquanto suas passadas são
                analisadas por meio de uma câmera. Com todos os dados em mãos, a
                equipe faz a análise completa para traçar/estabelecer seu perfil
                de pisada com exatidão.
              </p>
              <NavLink
                color="primary"
                bgcolor="white"
                size="small"
                to="/foot-id/agendamento"
              >
                agende seu teste
              </NavLink>
            </BannerText>
          </BannerItem>
        </SwiperSlide>
        <SwiperSlide>
          <BannerItem style={{ backgroundImage: `url(${topo4})` }}>
            <BannerText reverse>
              <h1>
                O que é e por que é <br /> importante saber sua pisada?
              </h1>
              <p>
                O modo como você pisa é caracterizado pela anatomia do seu
                corpo. Do quadril ao formato do seu pé, cada parte do seu corpo
                colabora com a forma como você pisa e como isso interfere
                diretamente na sua caminhada ou corrida.
              </p>
              <NavLink
                color="primary"
                bgcolor="white"
                size="small"
                to="/foot-id/agendamento"
              >
                agende seu teste
              </NavLink>
            </BannerText>
          </BannerItem>
        </SwiperSlide>
      </Swiper>
    </Banner>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

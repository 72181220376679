import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import NavLink from '../../../components/Buttons'

const TipoPisada = styled.section`
  min-height: 300px;
  padding: 30px 0;
  text-align: center;
  width: 100%;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  h1 {
    color: var(--primary-color);
    font-size: 26px;
    font-style: italic;
    font-weight: 400;
    line-height: 36px;

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 26px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 28px;
    }
  }

  h2 {
    color: var(--primary-color);
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 36px;
    margin-top: 50px;

    @media (max-width: 767px) {
      font-size: 32px;
      margin-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 40px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 40px;
    }
  }
`

const TipoPisadaWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  max-width: 100%;
  width: 1170px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 15px;
  }
`

const CardPisada = styled.article`
  border: 1px solid transparent;
  height: 530px;
  overflow: hidden;
  width: 340px;

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 310px;
  }

  &.supinada {
    border-color: var(--pisada-supinada);
  }

  &.neutra {
    border-color: var(--pisada-neutra);
  }

  &.pronada {
    border-color: var(--pisada-pronada);
  }
`

const ThumbPisada = styled.figure`
  background-color: #f7f7f7;
  height: 205px;
  width: 100%;
`

const ContentPisada = styled.div`
  height: 325px;
  padding: 20px;
  width: 100%;

  h3 {
    border-bottom: 3px solid;
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 20px;
    }
  }

  &.supinada {
    background-color: var(--pisada-supinada);
  }

  &.neutra {
    background-color: var(--pisada-neutra);
  }

  &.pronada {
    background-color: var(--pisada-pronada);
  }
`
export default function TiposPisadas({ pisadas }) {
  const { supinada, neutra, pronada } = pisadas

  return (
    <TipoPisada>
      <h1>
        Sabia que cada pisada pode influenciar em toda a sua performance,
        <br />
        seja qual for o esporte? Descubra a sua com o{' '}
        <strong>ASICS FOOT ID.</strong>
      </h1>
      <h2>
        Existem <strong>3 tipos de pisada:</strong>
      </h2>
      <TipoPisadaWrapper>
        <CardPisada className="supinada">
          <ThumbPisada>
            <img
              src={supinada}
              alt="Pisada Supinada"
              width="182"
              height="171"
            />
          </ThumbPisada>
          <ContentPisada className="supinada">
            <h3>Supinada:</h3>
            <p>
              é aquela em que a pessoa utiliza a parte externa do pé para se
              impulsionar. Isso exige mais esforço do calcanhar e por isso, é
              importante usar tênis com mais amortecimento para evitar lesões
              por impacto.
            </p>
          </ContentPisada>
        </CardPisada>
        <CardPisada className="neutra">
          <ThumbPisada>
            <img src={neutra} alt="Pisada Neutra" width="159" height="171" />
          </ThumbPisada>
          <ContentPisada className="neutra">
            <h3>Neutra:</h3>
            <p>
              é a mais comum e começa com o impulso no calcanhar e termina na
              planta do pé, quando o pé toca o chão de maneira uniforme. Pessoas
              com esse tipo de pisada se adaptam a calçados com foco no
              amortecimento.
            </p>
          </ContentPisada>
        </CardPisada>
        <CardPisada className="pronada">
          <ThumbPisada>
            <img src={pronada} alt="Pisada Pronada" width="162" height="171" />
          </ThumbPisada>
          <ContentPisada className="pronada">
            <h3>Pronada:</h3>
            <p>
              é a pisada que usa a parte interna do pé. Ela ocorre pelo fato das
              rotações internas do pé e do tornozelo serem mais acentuadas. Essa
              pisada exige tênis com um bom amortecimento e maior controle de
              estabilidade.
            </p>
          </ContentPisada>
        </CardPisada>
      </TipoPisadaWrapper>
      <NavLink to="/foot-id/agendamento" size="normal">
        agende seu teste grátis
      </NavLink>
    </TipoPisada>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
